import React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroHeader from "../components/heroheader"


class ProjectTemplate extends React.Component {
  render(data) {

  const project = get(this.props, 'data.nodeProject');

    return (
      <Layout>
      <SEO title={project.title} />

      <HeroHeader title={project.title} summary={project.created}/>


      <Container>
        {project.body && <div dangerouslySetInnerHTML={{ __html: project.body.processed }} /> }
      </Container> 

      </Layout>
    ) 
  }
}

export default ProjectTemplate

export const ProjectPageQuery = graphql`
  query ProjectBySlug($projectId: String!) {
    site {
      siteMetadata {
        title
      }
    }

    nodeProject(id: { eq: $projectId }) {
      id
      title
      created(formatString: "dddd Do, MMMM YYYY")
      path {
        alias
      }
      body {
        summary
        processed
      } 
   }
 }
 `


 const Container = styled.div`
  max-width: 1200px;
  margin: 60px auto;
 `